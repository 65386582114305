import { DecorativeIconProps } from '@lambdacurry/component-library';
import { FormikProps } from 'formik';
import { find } from 'lodash';
import * as Yup from 'yup';
import { TestContext } from 'yup';
import { AppsName, LeadType, WidgetAdjustments } from '../../types';
import { hexToRGB, luminance } from '../../util/colors';
import { CLXIconNames } from '../Bengal/IconRegistry';
import { ActiveApp } from './Sections/OrderSection/OrderSection';

export interface SectionProps {
    formikProps: FormikProps<ApplicationSettingsFormValues>;
    formIsDisabled?: boolean;
}

export const labels = {
    brand_primary_color: 'Primary Brand Color',
    brand_text_color: 'Font and Icon Color',
    widget_position: 'Desktop Position',
    widget_position_mobile: 'Mobile Position'
};

export const colorPickerDefaults = [
    { fieldName: 'brand_primary_color', defaultColor: '#0054FE' },
    { fieldName: 'brand_text_color', defaultColor: '#FFFFFF' }
];

export const colorThemeOptions = [
    { label: 'Light', value: 'light' },
    { label: 'Dark', value: 'dark' }
];

export const widgetSkinOptions = [
    { label: 'Default', value: 'default' },
    { label: 'Wallet', value: 'skin1' },
    { label: 'Modern', value: 'modern' }
];

export const widgetSizeOptions = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Large', value: 'large' }
];

export const widgetPositionOptions: { icon: CLXIconNames; value: string }[] = [
    { icon: 'positionTl', value: 'top-left' },
    { icon: 'positionTr', value: 'top-right' },
    { icon: 'positionBl', value: 'bottom-left' },
    { icon: 'positionBm', value: 'bottom-middle' },
    { icon: 'positionBr', value: 'bottom-right' }
];

export const widgetAnimationOptions = [
    { label: 'No Animation', value: '' },
    { label: 'Bounce', value: 'bounce' },
    { label: 'Heart Beat', value: 'heartBeat' },
    { label: 'Jello', value: 'jello' },
    { label: 'Pulse', value: 'pulse' },
    { label: 'Rubber Band', value: 'rubberBand' },
    { label: 'Shake', value: 'shake' },
    { label: 'Swing', value: 'swing' },
    { label: 'Tada', value: 'tada' },
    { label: 'Wobble', value: 'wobble' }
];

export const activationModalMessages: Record<'activate' | 'deactivate', string> = {
    activate: 'Are you sure you want to activate the application? The application will be visible to site visitors.',
    deactivate:
        'Are you sure you want to deactivate the application? Deactivating the application will remove it from your website. Your account will remain active.'
};

export const positioningInputs = [
    { name: 'top', label: 'Top' },
    { name: 'bottom', label: 'Bottom' },
    { name: 'left', label: 'Left' },
    { name: 'right', label: 'Right' },
    { name: 'mobile_top', label: 'Top' },
    { name: 'mobile_bottom', label: 'Bottom' },
    { name: 'mobile_left', label: 'Left' },
    { name: 'mobile_right', label: 'Right' }
];

export const widgetFontFamliyList = [
    { label: 'Default', value: 'inherit' },
    { label: 'Match Website Font Automatically', value: 'get_website_fonts' },
    { label: 'Arial', value: 'Arial' },
    { label: 'Helvetica', value: 'Helvetica' },
    { label: 'Times New Roman', value: 'Times New Roman' },
    { label: 'Courier New', value: 'Courier New' },
    { label: 'Verdana', value: 'Verdana' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Tahoma', value: 'Tahoma' },
    { label: 'Trebuchet MS', value: 'Trebuchet MS' },
    { label: 'Impact', value: 'Impact' },
    { label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode' },
    { label: 'Garamond', value: 'Garamond' },
    { label: 'Palatino Linotype', value: 'Palatino Linotype' },
    { label: 'Segoe UI', value: 'Segoe UI' },
    { label: 'Comic Sans MS', value: 'Comic Sans MS' },
    { label: 'Arial Black', value: 'Arial Black' },
    { label: 'Gill Sans', value: 'Gill Sans' },
    { label: 'Futura', value: 'Futura' },
    { label: 'Baskerville', value: 'Baskerville' },
    { label: 'Rockwell', value: 'Rockwell' },
    { label: 'Century Gothic', value: 'Century Gothic' },
    { label: 'Cinzel', value: 'Cinzel' },
    { label: 'Cormorant', value: 'Cormorant' },
    { label: 'Libre Franklin', value: 'Libre Franklin' },
    { label: 'Playfair', value: 'Playfair' },
    { label: 'Avenir Next', value: 'Avenir Next' },
    { label: 'Optima', value: 'Optima' },
    { label: 'Bebas Neue', value: 'Bebas Neue' },
];

export const widgetFontSizeList = [
    { label: '10', value: '10px' },
    { label: '11', value: '11px' },
    { label: '12', value: '12px' },
    { label: '13', value: '13px' },
    { label: '14', value: '14px' },
    { label: '15', value: '15px' },
    { label: '16', value: '16px' },
    { label: '17', value: '17px' },
    { label: '18', value: '18px' },
    { label: '19', value: '19px' },
    { label: '20', value: '20px' },
    { label: '21', value: '21px' },
    { label: '22', value: '22px' },
    { label: '23', value: '23px' },
    { label: '24', value: '24px' },
    { label: '25', value: '25px' },
    { label: '26', value: '26px' },
    { label: '27', value: '27px' },
    { label: '28', value: '28px' },
    { label: '29', value: '29px' },
    { label: '30', value: '30px' },
    { label: '31', value: '31px' },
    { label: '32', value: '32px' },
    { label: '33', value: '33px' },
    { label: '34', value: '34px' },
    { label: '35', value: '35px' },
    { label: '36', value: '36px' },
    { label: '37', value: '37px' },
    { label: '38', value: '38px' },
    { label: '39', value: '39px' },
    { label: '40', value: '40px' }
];

export const widgetFontWeightList = [
    { label: '100' },
    { label: '200' },
    { label: '300' },
    { label: '400' },
    { label: '500' },
    { label: '600' },
    { label: '700' },
    { label: '800' },
    { label: '900' },
];

export const MIN_WIDGET_FONT_SIZE = 14;
export const MAX_WIDGET_FONT_SIZE = 32;

export type WidgetDirection = 'horizontal' | 'vertical';

export interface ApplicationSettingsFormValues {
    api_key?: string;
    beta_widget_enabled: boolean;
    brand_primary_color?: string;
    brand_text_color?: string;
    ga_id?: string;
    gm_id?: string;
    data_layer_name?: string;
    id?: number;
    skin: string;
    skin_mobile: string;
    wallet_greeting_heading?: string;
    wallet_greeting_subheading?: string;
    widgetbar_expanded: boolean;
    widget_adjustments: WidgetAdjustments;
    widget_animation_style?: string;
    widget_code_css?: string;
    widget_code_mobile_css?: string;
    widget_disabled: boolean;
    widget_hidden: boolean;
    hidden_widgets: string[];
    hidden_widgets_mobile: string[];
    widgets_order: LeadType[];
    widgets_order_mobile: LeadType[];
    widget_position: string;
    widget_position_mobile: string;
    widget_size: string;
    widget_style: string;
    widget_direction?: WidgetDirection;
    widget_color_invert?: boolean;
    widget_icons_only?: boolean;
    collapse_threshhold: number;
    collapse_threshhold_mobile: number;
    widget_font?: string;
    widget_font_size?: string;
    widget_font_weight?: string;
    contrast?: number;
}

export const initialFormValues: ApplicationSettingsFormValues = {
    api_key: '',
    beta_widget_enabled: true,
    brand_primary_color: colorPickerDefaults[0].defaultColor,
    brand_text_color: colorPickerDefaults[1].defaultColor,
    ga_id: '',
    gm_id: '',
    hidden_widgets: [],
    hidden_widgets_mobile: [],
    data_layer_name: '',
    id: 0,
    skin: widgetSkinOptions[0].value,
    skin_mobile: widgetSkinOptions[0].value,
    wallet_greeting_heading: '',
    wallet_greeting_subheading: '',
    widgetbar_expanded: false,
    widget_animation_style: widgetAnimationOptions[0].value,
    widget_adjustments: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        mobile_top: 0,
        mobile_bottom: 0,
        mobile_left: 0,
        mobile_right: 0
    },
    widget_code_css: '',
    widget_code_mobile_css: '',
    widget_disabled: true,
    widget_hidden: true,
    widgets_order: [LeadType.SG, LeadType.CM, LeadType.TU, LeadType.IC, LeadType.BPN, LeadType.CH],
    widgets_order_mobile: [LeadType.SG, LeadType.CM, LeadType.TU, LeadType.IC, LeadType.BPN, LeadType.CH],
    widget_position: widgetPositionOptions[4].value,
    widget_position_mobile: widgetPositionOptions[4].value,
    widget_size: widgetSizeOptions[2].value,
    widget_style: colorThemeOptions[1].value,
    collapse_threshhold: 4,
    collapse_threshhold_mobile: 4,
    widget_font: 'inherit',
    widget_font_size: '16px',
    widget_font_weight: '400',
    contrast: 1
};

function ratioValidation(this: TestContext, textColor?: string) {
    // Check to see if the user cleared the input
    if (!textColor) {
        return true;
    }

    // Note: if the current contrast is set to 0, then we can skip this validation, because the user is accepting the invalid contrast
    const currentContrast = this.parent['contrast'];
    if (currentContrast === 0) {
        return true;
    }

    const primaryColor = this.parent['brand_primary_color'];
    const [r1, g1, b1] = hexToRGB(primaryColor)
        .split(', ')
        .map(x => parseInt(x, 10));
    const [r2, g2, b2] = hexToRGB(textColor)
        .split(', ')
        .map(x => parseInt(x, 10));
    const color1luminance = luminance(r1, g1, b1);
    const color2luminance = luminance(r2, g2, b2);
    const ratio =
        color1luminance > color2luminance
            ? (color2luminance + 0.05) / (color1luminance + 0.05)
            : (color1luminance + 0.05) / (color2luminance + 0.05);
    if (ratio > 1 / 3) {
        return this.createError({
            message: `The above color selections do not meet accessibility standards. The recommended color contrast ratio is 3:1 or above 0.66. The current ratio is: ${(
                1 - ratio
            ).toFixed(2)}`,
            path: 'contrast'
        });
    }

    return true;
}

export const applicationSettingsFormSchema = Yup.object().shape({
    api_key: Yup.string().nullable(),
    beta_widget_enabled: Yup.boolean().required(),
    brand_primary_color: Yup.string().nullable(),
    brand_text_color: Yup.string()
        .nullable()
        .test('contrast-ratio', 'Insufficient contrast ratio', ratioValidation as any), // (this: TestContext, value?: any) => boolean | ValidationError | Promise<boolean | ValidationError>
    ga_id: Yup.string().nullable(),
    gm_id: Yup.string().nullable(),
    data_layer_name: Yup.string().nullable(),
    id: Yup.number().nullable(),
    skin: Yup.string().required(),
    wallet_greeting_heading: Yup.string().nullable(),
    wallet_greeting_subheading: Yup.string().nullable(),
    widgetbar_expanded: Yup.boolean().required(),
    widget_adjustments: Yup.object().shape({
        top: Yup.number().nullable(),
        bottom: Yup.number().nullable(),
        left: Yup.number().nullable(),
        right: Yup.number().nullable(),
        mobile_top: Yup.number().nullable(),
        mobile_bottom: Yup.number().nullable(),
        mobile_left: Yup.number().nullable(),
        mobile_right: Yup.number().nullable()
    }),
    widget_animation_style: Yup.string().nullable(),
    widget_code_css: Yup.string().nullable(),
    widget_code_mobile_css: Yup.string().nullable(),
    widget_disabled: Yup.boolean().required(),
    widget_hidden: Yup.boolean().required(),
    widgets_order: Yup.array().of(Yup.string().required()).required(),
    widgets_order_mobile: Yup.array().of(Yup.string().required()).required(),
    widget_position: Yup.string().required(),
    widget_position_mobile: Yup.string().required(),
    collapse_threshhold: Yup.number().required(),
    collapse_threshhold_mobile: Yup.number().required(),
    font: Yup.string().nullable(),
    widget_font_size: Yup.string().nullable(),
    widget_font_weight: Yup.string().nullable(),
    widget_size: Yup.string().required('Please choose a size'),
    widget_style: Yup.string().required('Please choose a theme')
});

const ModuleIconMapper: Record<AppsName, DecorativeIconProps> = {
    [AppsName.FS]: { name: 'addUser', color: 'blue' },
    [AppsName.CM]: { name: 'concessionManager', color: 'green' },
    [AppsName.SG]: { name: 'schedule', color: 'purple' },
    [AppsName.IC]: { name: 'income', color: 'purple' },
    [AppsName.BPN]: { name: 'deal', color: 'success' },
    [AppsName.CH]: { name: 'chat', color: 'blue' },
    [AppsName.TU]: { name: 'sms', color: 'blue' },
    [AppsName.CB]: { name: 'chat', color: 'blue' },
    [AppsName.CBAI]: { name: 'chat', color: 'blue' },
    [AppsName.LC]: { name: 'chat', color: 'blue' }
};

export const getActiveAppsList = (module_order: LeadType[], activeApps: ActiveApp[]): any[] => {
    const formattedAppList = module_order.reduce((arr: any, appName: string) => {
        const appDetails = find(activeApps, ({ module_name }) => module_name === appName);
        const isActive = !!appDetails;

        if (isActive) {
            const iconObj = ModuleIconMapper[appName];
            arr.push({ ...appDetails, icon: iconObj });
        }

        return arr;
    }, []);

    return formattedAppList;
};
