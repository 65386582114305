import React, { FC, useEffect, useState } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect,
    ButtonOutlinePrimary,
    useSnackbar
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, RulesActionFactory, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { RuleAction } from '../../../../types';
import { handlePromise, InputSwitch } from '@lambdacurry/component-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export interface EntrataFormProps {
    initialValues: EntrataCrm;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface EntrataCrm extends Crm {
    configuration: {
        entrata_domain?: string;
        entrata_property_id?: number;
        entrata_lead_source_id?: number;
        sync_options: string;
        sync_calendar: boolean;
        entrata_toggle?: boolean;
    };
}

export const EntrataForm: FC<EntrataFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router, Api } = store;
    const { companyId } = router.params;
    const { addSnackbar } = useSnackbar();
    const emptyOptionList: Array<{ id: number; description: string }> = [];
    const [propertyList, setPropertyList] = useState(emptyOptionList);
    const [leadSourceList, setLeadSourceList] = useState(emptyOptionList);
    const [propertiesLoader, setPropertiesLoader] = useState<boolean>(false)
    const [leadSourcesLoader, setLeadSourcesLoader] = useState<boolean>(false)

    const ruleActions: Array<RuleAction> = [
        RulesActionFactory.ReplaceStringAction(
            'entrata_lead_source_id',
            'Replace Lead Source with new value',
            leadSourceList
        )
    ];

    const connectionSettingsComplete = (configuration: any): boolean => {
        return (
            configuration &&
            ['entrata_domain'].every((attr: string) => {
                return configuration[attr] && configuration[attr] !== '';
            })
        );
    };

    const preloadPropertyList = () => {
        if (connectionSettingsComplete(props.initialValues.configuration)) {
            fetchPropertyList(props.initialValues.configuration);
        }
    };

    useEffect(() => {
        preloadPropertyList();
        preloadLeadSourceList();
    }, []);

    const preloadLeadSourceList = () => {
        if (
            connectionSettingsComplete(props.initialValues.configuration) &&
            props.initialValues.configuration.entrata_property_id
        ) {
            fetchLeadSourceList(props.initialValues.configuration);
        }
    };

    const fetchPropertyList = async (configuration: any) => {
        setPropertiesLoader(true)
        if (!connectionSettingsComplete(configuration)) {
            alert('Please complete the Connection Settings section before loading the property list.');
            return;
        }
        const [response, error] = await handlePromise<{ data: any }>(
            Api.client.get(`crm/entrata/properties/${configuration.entrata_domain}`)
        );
        if (
            !response?.data ||
            response.data.length === 0 ||
            error
        ) {
            addSnackbar('Failed to fetch property list data.', { variant: 'error' });
            setPropertiesLoader(false)
            return console.error('error: ', error);
        }

        const propertyListTmp = response?.data
        setPropertiesLoader(false)
        setPropertyList(propertyListTmp);

        if (
            configuration.entrata_property_id &&
            propertyListTmp.filter((property: { id: number }) => {
                return property.id === configuration.entrata_property_id;
            }).length === 0
        ) {
            addSnackbar('The currently configured property is no longer available. Please choose another.', {
                variant: 'error'
            });
            return console.error('error: ', error);
        }
    };

    const fetchLeadSourceList = async (configuration: any) => {
        setLeadSourcesLoader(true)
        if (!connectionSettingsComplete(configuration) || !configuration.entrata_property_id) {
            alert(
                'Please complete the Connection Settings section and Property Name selection before loading the Lead Source list.'
            );
            return;
        }
        const [response, error] = await handlePromise<{ data: any }>(
            Api.client.get(`crm/entrata/properties/sources/${configuration.entrata_domain}/${configuration.entrata_property_id}`)
        );
        if (
            !response?.data ||
            response.data.length === 0 ||
            error
        ) {
            addSnackbar('Failed to fetch lead source list data.', { variant: 'error' });
            setLeadSourcesLoader(false)
            return console.error('error: ', error);
        }
        const leadSourceListTmp = response?.data
        setLeadSourceList(leadSourceListTmp);
        setLeadSourcesLoader(false)
        if (
            configuration.entrata_lead_source_id &&
            leadSourceListTmp.filter((leadSource: { id: number }) => {
                return leadSource.id === configuration.entrata_lead_source_id;
            }).length === 0
        ) {
            addSnackbar('The currently configured lead source is no longer available. Please choose another.', {
                variant: 'error'
            });
            return console.error('error: ', error);
        }
    };

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<EntrataCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.entrata);
    };

    const handleSubmit = async (values: EntrataCrm, actions: FormikHelpers<EntrataCrm>) => {
        delete values.configuration['entrata_source'];
        if (!values.configuration.entrata_toggle) {
            values.configuration.entrata_toggle = false;
        }
        await onSubmit(values, actions, crmConfigTypes.entrata);
    };

    const initialValues = props.initialValues;
    if (!initialValues.configuration) {
        initialValues.configuration = { sync_options: 'pull', sync_calendar: true };
    }

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            initialValues={initialValues}
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<EntrataCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Entrata" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    required={true}
                                    helperText="Unique name for this CRM Configuration"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader
                                    title="Connection Settings"
                                    subtitle="Contact Entrata support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.entrata_domain"
                                    label="Subdomain"
                                    required={true}
                                    helperText="Entrata Subdomain"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />

                                <InputSwitch
                                    label="Send Appointment Tour Type"
                                    formikProps={formikProps}
                                    name="configuration.entrata_toggle"
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Property Options" />
                                <InputSelect
                                    label="Property Name"
                                    name="configuration.entrata_property_id"
                                    options={propertyList || []}
                                    optionValueKey="id"
                                    optionLabelKey="description"
                                    disabled={!propertyList}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                    helperText="The list of properties is loaded dynamically from the Entrata servers. Please verify your Connection Settings if the Load Available Properties button fails to populate the drop-down list."
                                />
                                <div style={{ margin: '26px' }}>
                                    <Button
                                        onClick={() => fetchPropertyList(formikProps.values.configuration)}
                                        disabled={!connectionSettingsComplete(formikProps.values.configuration)}
                                    >
                                        {propertiesLoader && <FontAwesomeIcon icon={faSpinner} spin size="lg" />} Load Available Properties
                                    </Button>
                                </div>
                                <InputSelect
                                    label="Lead Source"
                                    name="configuration.entrata_lead_source_id"
                                    options={leadSourceList || []}
                                    optionValueKey="id"
                                    optionLabelKey="description"
                                    disabled={!leadSourceList}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                    helperText="The list of lead sources is loaded dynamically from the Entrata servers. Please verify your Connection Settings if the Load Available Lead Sources button fails to populate the drop-down list."
                                />
                                <div style={{ margin: '26px' }}>
                                    <Button
                                        onClick={() => fetchLeadSourceList(formikProps.values.configuration)}
                                        disabled={
                                            !connectionSettingsComplete(formikProps.values.configuration) ||
                                            !formikProps.values.configuration.entrata_property_id
                                        }
                                    >
                                        {leadSourcesLoader && <FontAwesomeIcon icon={faSpinner} spin size="lg" />} Load Available Lead Sources
                                    </Button>
                                </div>
                                <AppSectionHeader title="Sync Options" />
                                <InputSelect
                                    label="Push/Pull"
                                    name="configuration.sync_options"
                                    options={[
                                        { val: 'push', label: 'Push new leads to CRM' },
                                        { val: 'pull', label: 'Push new leads to CRM and pull updated leads from CRM' }
                                    ]}
                                    optionValueKey="val"
                                    optionLabelKey="label"
                                    helperText="The pull option will poll your Entrata CRM periodically to see if any lead data, such as contact info or appointment times, has changed."
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
